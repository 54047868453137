import React from 'react';
import * as styles from './process.module.scss';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
const Process = ({ temp }) => {
  return (
    <IntlContextConsumer>{
      ({ languages, language: currentLocale }) =>
        <div className={styles.questionsContent}>
          {/* 移动端显示 */}
          <ul className={styles.list}>
            {
              temp.map((item, i) => (
                <li key={`${item}${i}`}>
                  <div className={styles.mobileWrapContent}>
                    <p className={styles.circelCount}>{i + 1}</p>
                    <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.text }}></p>
                    <p className={styles.time} dangerouslySetInnerHTML={{ __html: item.time }} ></p>
                  </div>
                  <img src={item.image}></img>
                </li>
              ))
            }
          </ul>
          {/* pc端显示 */}

          <ul className={styles.pcList}>
            {
              temp.map((item, i) => (
                <li key={`${item}${i}`}>
                  <img className="yellowHoverUpImg" src={item.image}></img>
                  <p className={styles.circelCount}>{i + 1}</p>
                  <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.text }} style={currentLocale === 'en' ? { width: '200px', wordBreak: 'normal', minHeight: '60px' } : { wordBreak: 'normal' }}></p>
                  <p className={styles.time} dangerouslySetInnerHTML={{ __html: item.time }} style={currentLocale === 'en' ? { width: '200px' } : {}}></p>
                  {/* <img src={i < 4 ? item.image: temp[temp.length - (i - 4 + 1)].image}></img>
                <p className={styles.circelCount}>{i < 4 ? i + 1: temp.length - (i - 4)}</p>
                <p className={styles.title}>{i < 4 ? item.text: temp[temp.length - (i - 4 + 1)].text}</p>
                {
                  i == 3?
                  <p className={styles.arrow_down_wrap}>
                    <span className={styles.arrow_down}></span>
                  </p>
                  :null
                } */}
                </li>
              ))
            }
          </ul>
        </div>
    }</IntlContextConsumer>
  );
};

export default Process;
