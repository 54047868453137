import React from 'react';
import Link from '../link';
import * as styles from './sidebar.module.scss';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileSidebar: false,
    };
  }

  toggleMobileSidebar = () => {
    this.setState({ showMobileSidebar: !this.state.showMobileSidebar });
  }

  render() {
    const { temp, pathname } = this.props;
    const { showMobileSidebar } = this.state;
    return (
      <div className={`${styles.sidebar}`}>
        {
          showMobileSidebar
            ? <div className={`${styles.mobileSidebar}`}>
              {
                temp && temp.map((item, i) => (
                  <div key={`${item}${i}`} className={`${styles.sidebarItem} ${pathname.replace('/en/', '/').replace('/zh/', '/').includes(item.url) ? 'sidebarItemActive' : ''}`}>
                    <Link to={item.url} className={`${pathname.replace('/en/', '/').replace('/zh/', '/').includes(item.url) ? 'sidebarItemActive' : ''}`}>
                      <p>{item.text}</p>
                    </Link>
                  </div>
                ))
              }
            </div>
            : null
        }
        <div className={`${styles.pcSidebar}`}>
          {
            temp && temp.map((item, i) => <div key={`${item}${i}`} className={`${styles.sidebarItem} ${pathname.replace('/en/', '/').replace('/zh/', '/').includes(item.url) ? 'sidebarItemActive' : ''}`}>
              <Link to={item.url} className={`${pathname.replace('/en/', '/').replace('/zh/', '/').includes(item.url) ? 'sidebarItemActive' : ''}`}>
                <p>{item.text}</p>
              </Link>
            </div>)
          }
        </div>
        <div className={`${styles.mobileSidebarParent}`} onClick={this.toggleMobileSidebar}>...</div>
      </div >
    );
  }
}

export default Sidebar;
