import React from 'react';
import Swipe from '../../components/common/swipe';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Sidebar from '../../components/universities/sidebar';

const CampusLayout = ({
  copywriting, location, children,
}) => {
  const data = copywriting.campusRecruitment;
  const { pathname } = location || {};
  return (
    <div className={'home'}>
      <Swipe items={data.banner} mod="home" />
      <Layout copywriting={copywriting} location={location}>
        <SEO title={data.seo.title}
          keywords={data.seo.keywords} description={data.seo.description} />
        <div className={'questions'}>
          {data.sidebar && <Sidebar pathname={pathname} temp={data.sidebar}></Sidebar>}
          {children}
        </div>
      </Layout>
    </div>
  );
};

export default CampusLayout;
